<h1 mat-dialog-title>Notifications</h1>
<div mat-dialog-content class="content">
    <table mat-table [dataSource]="data">
        <tr mat-header-row *matHeaderRowDef="columnKeys"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnKeys"></tr>
        <ng-container *ngFor="let column of columnKeys; let i = index" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef> {{columnLabels[i]}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
          </ng-container>
    </table>
</div>